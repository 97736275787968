import { localdata } from "@/helpers/local-data";
import { appProvider } from "./../../../app-providers";
import { NftModel } from "@/models/nft-model";
import { MoralisModel } from "@/models/moralis-model";
import { moralisHelper } from "@/helpers/moralis-helper";
import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper } from "@/helpers/bignumber-helper";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";
import { asyncAction, task } from "mobx-utils";
import moment from "moment";
import { Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { ApiService } from "@/service/api-service";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import Web3 from "web3";
import { chunk, isEmpty } from "lodash-es";

export class MyCharacterViewmodel {
  _disposers: IReactionDisposer[] = [];

  @observable myNfts: any = [];
  @observable nftIds: any = [];
  @observable characterNFTs?: Array<NftModel> = [];
  @observable monstersPerPage = 8;
  @observable totalFilteredMonster?: number = 0;
  @observable isGrid?: boolean = true;

  searchParams: any = {};
  filteredMonsters: NftModel[] = [];
  @observable slicedMyMonsters: NftModel[] = [];
  @observable totalOwnedMonsterPage = 0;
  @observable currentPage = 1;

  constructor() {
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.loadData();
        }
      ),
    ];
  }

  @action.bound setGridMode(val) {
    this.isGrid = val;
  }

  @asyncAction *loadData() {
    loadingController.increaseRequest();
    try {
      if (!walletStore.isMetamask || !walletStore.account) return;

      yield this.getMyMonsters();

      if (this.isNewMonstersFromLocal) {
        const newMonsters = localdata.getNewMonsters();
        this.nftIds = [...this.nftIds, ...newMonsters.map((monster) => monster.ID)];
        this.totalFilteredMonster = this.nftIds.length;
      } else {
        localdata.clearNewMonsters();
      }

      if (this.nftIds && this.nftIds.length > 0) {
        const groups = chunk(this.nftIds, 100);
        const characters: any[] = [];
        for (const ids of groups) {
          const nfts = yield appProvider.api.nft.find({
            // eslint-disable-next-line
            ID_in: ids,
            _limit: -1,
          });
          characters.push(...nfts);
        }

        this.characterNFTs = characters as Array<NftModel>;
      }
      this.searchMyMonsters({});
    } catch (error) {
      console.error(error);
      snackController.error(error);
    } finally {
      loadingController.decreaseRequest();
    }
  }

  @computed get isNewMonstersFromLocal() {
    const newMonsters = localdata.getNewMonsters();
    if (!newMonsters || newMonsters.length === 0) return false;
    const newMonster = newMonsters[newMonsters.length - 1];
    return this.myNfts?.findIndex((nft) => nft.token_id === newMonster.ID) === -1;
  }

  @action.bound searchMyMonsters(params) {
    this.searchParams = params;
    if (isEmpty(this.searchParams)) {
      this.totalFilteredMonster = this.characterNFTs?.length;
      this.filteredMonsters = [...this.characterNFTs!];
    } else {
      this.currentPage = this.searchParams.page;

      let monsters = this.characterNFTs as any;
      if (this.searchParams.id) monsters = monsters.filter((monster) => monster.ID.includes(this.searchParams.id));
      if (this.searchParams.type === 0 || this.searchParams.type === 1)
        monsters = monsters.filter((monster) => monster.Grade == this.searchParams.type);
      if (this.searchParams.monsterClass)
        monsters = monsters.filter((monster) => monster.Class == this.searchParams.monsterClass);
      if (this.searchParams.race) monsters = monsters.filter((monster) => monster.Race == this.searchParams.race);

      this.filteredMonsters = monsters;
      this.totalFilteredMonster = monsters.length;
    }
    this.slicedMyMonsters = this.filteredMonsters.slice(
      (this.currentPage - 1) * this.monstersPerPage,
      this.currentPage * this.monstersPerPage
    );

    this.calculateTotalPage();
  }

  changeMyMonsterPage(page) {
    this.currentPage = page;
    this.searchMyMonsters({ ...this.searchParams, page });
  }

  @asyncAction *getMyMonsters() {
    try {
      const response = yield moralisHelper.getAllAccountNFTs(
        walletStore.account,
        process.env.VUE_APP_CHAIN_ID === "97" ? "bsc testnet" : "bsc"
      );

      this.totalFilteredMonster = response!.total!;
      this.myNfts = response!.results! as Array<MoralisModel>;
      this.nftIds = this.myNfts.map((moralisNFT) => moralisNFT.token_id);
      // cache nftIds
      localdata.setMonsterNftIds(walletStore.account, this.nftIds);
    } catch (error) {
      console.error(error);
      // get nftIds from cached
      this.nftIds = localdata.getMonsterNftIds(walletStore.account);
      this.totalFilteredMonster = this.nftIds.length;
    }
  }

  calculateTotalPage() {
    if (this.totalFilteredMonster! % this.monstersPerPage! == 0)
      this.totalOwnedMonsterPage = this.totalFilteredMonster! / this.monstersPerPage!;
    else this.totalOwnedMonsterPage = Math.floor(this.totalFilteredMonster! / this.monstersPerPage!) + 1;
  }

  @computed get hasCharacter() {
    return this.totalFilteredMonster! > 0;
  }
}
