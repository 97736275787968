



















































import { NftModel } from "@/models/nft-model";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Prop } from "vue-property-decorator";
import { MyCharacterViewmodel } from "../viewmodels/my-character-viewmodel";

@Observer
@Component({
  components: {
    AllCharacterTab: () =>
      import("@/modules/my-character/components/all-characer-tab.vue"),
  },
})
export default class MyCharacter extends Vue {
  @Provide() vm = new MyCharacterViewmodel();
  @Prop() character!: NftModel;
  tab = 0;
}
